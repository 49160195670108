import * as React from 'react';
import PropTypes from 'prop-types';
import Loading from '../../../components/body-loading';
import { useUserContext } from '../../../context/auth-context';
import { getUserDetails, startOktaService } from '../../../utils/okta-util';

function LoginCallback({ location }) {
  const { state: { authClient }, dispatch: authDispatch } = useUserContext();

  const setUserDetails = async () => {
    const user = await getUserDetails(authClient);
    authDispatch({
      type: 'user',
      payload: {
        user
      }
    });
    localStorage.setItem('user', JSON.stringify(user));
    await startOktaService(authClient);

    // Redirect URL logic...
    const redirectUrl = sessionStorage.getItem('redirectUrl');

    if (redirectUrl) {
      window.location.replace(redirectUrl);
      sessionStorage.removeItem('redirectUrl');
    } else {
      window.location.replace('/home');
    }
  };

  const getTokens = () => {
    if (!location.search) { // Check for Url params and also localstorage
      const user = localStorage.getItem('user');
      if (user) {
        const redirectUrl = sessionStorage.getItem('redirectUrl');
        if (redirectUrl) {
          window.location.replace(redirectUrl);
          sessionStorage.removeItem('redirectUrl');
        } else {
          window.location.replace('/home');
        }
      } else {
        window.location.replace('/');
      }
    } else {
      const urlParams = new URLSearchParams(location.search);

      if (!(urlParams.get('code') && urlParams.get('state'))) {
        window.location.replace('/unauthorized');
        return;
      }

      authClient.token
        .parseFromUrl()
        .then(res => {
          // Dispatch Tokens
          authDispatch({
            type: 'setToken',
            payload: {
              token: res.tokens
            }
          });

          // Setting User details...
          setUserDetails();
        });
    }
  };

  React.useEffect(() => {
    getTokens();
  }, []);

  return (
    <Loading />
  );
}

LoginCallback.propTypes = {
  location: PropTypes.shape.isRequired
};

export default LoginCallback;
